.text{
  font-size: 150%;
  line-height: 130%;

}

.Name {
    margin-bottom: 1rem;
}

.Kontakt {
  display: flex;
  margin: 2% auto;
  justify-content: space-evenly;
  text-align: center;
}

@media (max-width: 800px) {
  .Kontakt {
    flex-direction: column;
  }
  #ThorbenBesterMannPlsEinsNull {
    margin-top: 5%;
  }
}