.parent {
    display: grid;
    place-items: center;
    /* background-color:blue; */
}

.liveForm {
    display: flex;
    justify-content: space-evenly;
    margin: 2% auto 0 auto;
    width: 70%;
    padding: 0 5%
}

.liveForm {
    margin-bottom: 20px;
}

@media (max-width: 1081px) {
    .liveForm {
        padding: 0;
    }

    .liveForm {
        margin-bottom: 0;
    }
}