@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
} */

.Login {
  flex-direction: column;
  display: flex;
  width: 40%;
  margin: 5% auto
}

.textField {
  font-size: 150%;
}

.button {
  display: flex;
  text-decoration: none;
  color:  rgb(0,0,0);
  background-color:rgba(199, 195, 195, 0.801);
  font-size: 150%;
  justify-content: space-evenly;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 10px 10px 7px #999;
}

.button:hover {
  cursor: pointer;
  background-color: rgba(173, 173, 173, 0.801);
}

.button:active {
  box-shadow: 6px 6px 7px #999;
  transform: translateY(4px);
}

@media (max-width: 1081px) {
  .Login {
    width: 90%
  }
}

.refresh {
  display: flex;
  flex: initial;
  margin: auto 0;
}

.spin {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
.ESHL,
.ESHLshort {
  display: flex;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 27px;
}

.ESHLshort {
  display: none
}

.navbar {
  background-color: rgb(43, 59, 67);
  height: 80px;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 10% 10% 60% 20%;
  grid-template-areas:
      'menu qr titel logout';
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}
  
.menu-bars {
  display: flex;
  font-size: 2rem;
  background: none;
  justify-self: start;
  padding-left: 24px;
}
  
.nav-menu {
  background-color:rgb(43, 59, 67);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -200%;
  transition: 850ms;
  z-index: 1000;
}
  
.nav-menu.active {
  left: 0;
  transition: 350ms;
  z-index: 1000;
}
  
.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}
  
.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}
  
.nav-text a:hover {
  background-color: #1a83ff;
}
  
.nav-menu-items {
  width: 100%;
  padding-left: 0
}
  
.navbar-toggle {
  background-color:rgb(43, 59, 67);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.zw {
  display: none;
}

.Logout {
  display: flex;
  text-decoration: none;
  background-color:rgb(43, 59, 67);
  font-size: 150%;
  outline: none;
  border-radius: 15px;
  border: 2px solid black;
  padding: 2%;
  margin-right: 2rem;
  color: white;
  justify-self: right;
}

@media (max-width: 700px) {
  .ESHL,
  #qr,
  .qr {
    display: none
  }
  .ESHLshort {
    display: flex;
    justify-self: center;
  }
  .navbar {
    grid-template-columns: 15% 70% 15%;
  }
}
body {
  font-family: 'Lato', sans-serif;
}

.pic {
  display: block;
  width: 100%;
}

#hintergrundBtn,
#tippsBtn,
#kennzahlBtn {
  display: flex;
  text-decoration: none;
  color:  rgb(0,0,0);
  background-color:rgba(199, 195, 195, 0.801);
  font-size: 150%;
  justify-content: space-evenly;
  margin: 2% 0% 2% 0%;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 10px 10px 7px #999;
}

#hintergrundBtn:hover,
#tippsBtn:hover,
#kennzahlBtn:hover,
.pic:hover {
  cursor: pointer;
  background-color: rgba(173, 173, 173, 0.801);
}

#hintergrundBtn:active,
#tippsBtn:active,
#kennzahlBtn:active {
  box-shadow: 6px 6px 7px #999;
  transform: translateY(4px);
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 3; /* Sit on top */
  padding-top: 5%; /* Location of the box */
  padding-bottom: 5%;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
}

.picModal {
  display: block;
  width: 100%
}

@media (max-width: 570px) {
  .refresh {
    display: none;
  }
  #hintergrundBtn,
  #tippsBtn,
  #kennzahlBtn,
  .pic {
    width: 90%;
    margin: 20px 5%
  }
}
.refresh {
    display: flex;
    flex: initial;
    margin: auto 0;
  }
  
.spin {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
  
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
.btn {
  display: flex;
  text-decoration: none;
  color:  rgb(0,0,0) !important;
  background-color:rgba(199, 195, 195, 0.801) !important;
  font-size: 100%;
  justify-content: space-evenly;
  margin: 2% 0% 2% 0%;
  width: 100%;
  outline: none !important;
  border: none;
  border-radius: 15px;
  box-shadow: 10px 10px 7px #999 !important;
  padding: 0
}

.btn:hover {
  cursor: pointer;
  background-color: rgba(173, 173, 173, 0.801) !important;
}

.btn:active{
  box-shadow: 6px 6px 7px #999;
  transform: translateY(4px);
}

.dontShowOnMobile {
  display: flex;
  justify-content: center;
}

.table{
  font-size: 150%;
}

.custom-file-upload-rangliste {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: default;
  background: rgb(43, 59, 67);
}

@media (max-width: 768px) {
  .dontShowOnMobile {
    display: none;
  }
}
.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
    
.input[type="file"] {
    display: none;
}
  
.custom-file-upload {
    border-radius: 50%;
    display: inline-block;
    position: relative;
    padding: 6px;
    cursor: pointer;
    background: rgb(43, 59, 67);
}
  
.img-wrap{
    display: flex;
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
}

.img-upload:before{
    content: '\f093';
    position: absolute;
    padding-top: 28px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 200%;
    color: rgb(43, 59, 67);
    width: 100px;
    height: 100px;
    opacity: 0;
    transition: .5s ease;
    background-color: gray;
}

.img-upload:hover:before{
   opacity: 1;
}

.img {
    width: auto;
    height: 100%;
    margin: auto;
}
  
.anreizContainer {
    display: flex;
    flex-direction: row;
}

.anreizContent {
    width: 45%;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .anreizContainer {
        flex-direction: column;
    }
    .anreizContent {
        width: 90%;
    }
}
.parent {
    display: grid;
    place-items: center;
    /* background-color:blue; */
}

.liveForm {
    display: flex;
    justify-content: space-evenly;
    margin: 2% auto 0 auto;
    width: 70%;
    padding: 0 5%
}

.liveForm {
    margin-bottom: 20px;
}

@media (max-width: 1081px) {
    .liveForm {
        padding: 0;
    }

    .liveForm {
        margin-bottom: 0;
    }
}
.all-page-container {
    display: grid;
    place-items: center
}
.text{
  font-size: 150%;
  line-height: 130%;

}

.Name {
    margin-bottom: 1rem;
}

.Kontakt {
  display: flex;
  margin: 2% auto;
  justify-content: space-evenly;
  text-align: center;
}

@media (max-width: 800px) {
  .Kontakt {
    flex-direction: column;
  }
  #ThorbenBesterMannPlsEinsNull {
    margin-top: 5%;
  }
}
.react-calendar {
    width: 70%;
    margin: 2% auto;
    background: white;
    border: 1px solid #a0a096;
    line-height: 1.125em;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  /* .react-calendar__tile--now {
    background: #a1ff84;
  } */
  /* .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  } */
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: #006edc;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  
.parent {
  display: flex;
  flex-direction: column;
  place-items: center;
  margin: 0 auto;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 3; /* Sit on top */
  padding-top: 5%; /* Location of the box */
  padding-bottom: 5%;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
}


.picKalender {
  display: block;
  width: 100%;
}

select {
  width: 40%;
  font-size: 150%;
  padding: 0 1% 0 1%;
  margin-bottom: 3%;
  height: 40px;
  padding-left: 10px;
  background-color: rgba(199, 195, 195, 0.801);
  font-size: 150%;
  outline: none;
  cursor: pointer;
}

.tagForm {
  margin: 1% 0% 2% 0%;
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

input[type="radio"] {
  display: none;
}

.historischeKennzahlen {
  margin: 1% auto;
  text-decoration: none;
  color: rgb(0,0,0);
  background-color:rgba(199, 195, 195, 0.801);
  font-size: 150%;
  text-align: center;
  outline: none;
  border: none;
  width: 70%
}

.labelClass {
  text-decoration: none;
  color: rgb(0,0,0);
  background-color:rgba(199, 195, 195, 0.801);
  font-size: 150%;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 10px 10px 7px #999;
  padding: 0 1%;
}

.labelClass:hover {
  cursor: pointer;
  background-color: #006edc;
  color: white;
}

input[type="radio"]:checked + .labelClass {
  box-shadow: 6px 6px 7px #999;
  transform: translateY(4px);
  background-color: #006edc;
  color: white;
}

.ZeitspanneStyle {
  justify-content: space-evenly;
  margin-top: 2%;
  display: flex;
  width: 20%;
  flex-direction: column;
  text-align: center;
}

.react-datetime-picker__inputGroup__divider,
.react-datetime-picker__inputGroup__leadingZero {
  margin-left: 0;
}

.enddate {
  margin: 1% 0
}

.green {
  background-color: green;
}

.red {
  background-color: red
}

@media (max-width: 1081px) {
  .tagForm {
    flex-direction: column;
  }
  .ZeitspanneStyle {
    width: 95%;
    margin: 0
  }
  .labelClass {
    margin-bottom: 20px;
  }
  select {
    width: 95%
  }
  .react-calendar {
    width: 95%;
  }
  .tagForm {
    width: 95%;
  }
  .historischeKennzahlen {
    width: 95%
  }
}


.passenderPlot {
    width: 100%;
    height: 100%
}
.ZeitspanneStyle {
    justify-content: space-evenly;
}
.KalenderSeite > form {
    margin: 2% auto 0 auto
}
