.anreizContainer {
    display: flex;
    flex-direction: row;
}

.anreizContent {
    width: 45%;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .anreizContainer {
        flex-direction: column;
    }
    .anreizContent {
        width: 90%;
    }
}