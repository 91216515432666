.react-calendar {
    width: 70%;
    margin: 2% auto;
    background: white;
    border: 1px solid #a0a096;
    line-height: 1.125em;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  /* .react-calendar__tile--now {
    background: #a1ff84;
  } */
  /* .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  } */
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: #006edc;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  
.parent {
  display: flex;
  flex-direction: column;
  place-items: center;
  margin: 0 auto;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 3; /* Sit on top */
  padding-top: 5%; /* Location of the box */
  padding-bottom: 5%;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
}


.picKalender {
  display: block;
  width: 100%;
}

select {
  width: 40%;
  font-size: 150%;
  padding: 0 1% 0 1%;
  margin-bottom: 3%;
  height: 40px;
  padding-left: 10px;
  background-color: rgba(199, 195, 195, 0.801);
  font-size: 150%;
  outline: none;
  cursor: pointer;
}

.tagForm {
  margin: 1% 0% 2% 0%;
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

input[type="radio"] {
  display: none;
}

.historischeKennzahlen {
  margin: 1% auto;
  text-decoration: none;
  color: rgb(0,0,0);
  background-color:rgba(199, 195, 195, 0.801);
  font-size: 150%;
  text-align: center;
  outline: none;
  border: none;
  width: 70%
}

.labelClass {
  text-decoration: none;
  color: rgb(0,0,0);
  background-color:rgba(199, 195, 195, 0.801);
  font-size: 150%;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 10px 10px 7px #999;
  padding: 0 1%;
}

.labelClass:hover {
  cursor: pointer;
  background-color: #006edc;
  color: white;
}

input[type="radio"]:checked + .labelClass {
  box-shadow: 6px 6px 7px #999;
  transform: translateY(4px);
  background-color: #006edc;
  color: white;
}

.ZeitspanneStyle {
  justify-content: space-evenly;
  margin-top: 2%;
  display: flex;
  width: 20%;
  flex-direction: column;
  text-align: center;
}

.react-datetime-picker__inputGroup__divider,
.react-datetime-picker__inputGroup__leadingZero {
  margin-left: 0;
}

.enddate {
  margin: 1% 0
}

.green {
  background-color: green;
}

.red {
  background-color: red
}

@media (max-width: 1081px) {
  .tagForm {
    flex-direction: column;
  }
  .ZeitspanneStyle {
    width: 95%;
    margin: 0
  }
  .labelClass {
    margin-bottom: 20px;
  }
  select {
    width: 95%
  }
  .react-calendar {
    width: 95%;
  }
  .tagForm {
    width: 95%;
  }
  .historischeKennzahlen {
    width: 95%
  }
}

