@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
} */

.Login {
  flex-direction: column;
  display: flex;
  width: 40%;
  margin: 5% auto
}

.textField {
  font-size: 150%;
}

.button {
  display: flex;
  text-decoration: none;
  color:  rgb(0,0,0);
  background-color:rgba(199, 195, 195, 0.801);
  font-size: 150%;
  justify-content: space-evenly;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 10px 10px 7px #999;
}

.button:hover {
  cursor: pointer;
  background-color: rgba(173, 173, 173, 0.801);
}

.button:active {
  box-shadow: 6px 6px 7px #999;
  transform: translateY(4px);
}

@media (max-width: 1081px) {
  .Login {
    width: 90%
  }
}

.refresh {
  display: flex;
  flex: initial;
  margin: auto 0;
}

.spin {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}