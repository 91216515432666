.ESHL,
.ESHLshort {
  display: flex;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 27px;
}

.ESHLshort {
  display: none
}

.navbar {
  background-color: rgb(43, 59, 67);
  height: 80px;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 10% 10% 60% 20%;
  grid-template-areas:
      'menu qr titel logout';
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}
  
.menu-bars {
  display: flex;
  font-size: 2rem;
  background: none;
  justify-self: start;
  padding-left: 24px;
}
  
.nav-menu {
  background-color:rgb(43, 59, 67);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -200%;
  transition: 850ms;
  z-index: 1000;
}
  
.nav-menu.active {
  left: 0;
  transition: 350ms;
  z-index: 1000;
}
  
.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}
  
.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}
  
.nav-text a:hover {
  background-color: #1a83ff;
}
  
.nav-menu-items {
  width: 100%;
  padding-left: 0
}
  
.navbar-toggle {
  background-color:rgb(43, 59, 67);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.zw {
  display: none;
}

.Logout {
  display: flex;
  text-decoration: none;
  background-color:rgb(43, 59, 67);
  font-size: 150%;
  outline: none;
  border-radius: 15px;
  border: 2px solid black;
  padding: 2%;
  margin-right: 2rem;
  color: white;
  justify-self: right;
}

@media (max-width: 700px) {
  .ESHL,
  #qr,
  .qr {
    display: none
  }
  .ESHLshort {
    display: flex;
    justify-self: center;
  }
  .navbar {
    grid-template-columns: 15% 70% 15%;
  }
}