body {
  font-family: 'Lato', sans-serif;
}

.pic {
  display: block;
  width: 100%;
}

#hintergrundBtn,
#tippsBtn,
#kennzahlBtn {
  display: flex;
  text-decoration: none;
  color:  rgb(0,0,0);
  background-color:rgba(199, 195, 195, 0.801);
  font-size: 150%;
  justify-content: space-evenly;
  margin: 2% 0% 2% 0%;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 10px 10px 7px #999;
}

#hintergrundBtn:hover,
#tippsBtn:hover,
#kennzahlBtn:hover,
.pic:hover {
  cursor: pointer;
  background-color: rgba(173, 173, 173, 0.801);
}

#hintergrundBtn:active,
#tippsBtn:active,
#kennzahlBtn:active {
  box-shadow: 6px 6px 7px #999;
  transform: translateY(4px);
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 3; /* Sit on top */
  padding-top: 5%; /* Location of the box */
  padding-bottom: 5%;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
}

.picModal {
  display: block;
  width: 100%
}

@media (max-width: 570px) {
  .refresh {
    display: none;
  }
  #hintergrundBtn,
  #tippsBtn,
  #kennzahlBtn,
  .pic {
    width: 90%;
    margin: 20px 5%
  }
}