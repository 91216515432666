.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
    
.input[type="file"] {
    display: none;
}
  
.custom-file-upload {
    border-radius: 50%;
    display: inline-block;
    position: relative;
    padding: 6px;
    cursor: pointer;
    background: rgb(43, 59, 67);
}
  
.img-wrap{
    display: flex;
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
}

.img-upload:before{
    content: '\f093';
    position: absolute;
    padding-top: 28px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 200%;
    color: rgb(43, 59, 67);
    width: 100px;
    height: 100px;
    opacity: 0;
    transition: .5s ease;
    background-color: gray;
}

.img-upload:hover:before{
   opacity: 1;
}

.img {
    width: auto;
    height: 100%;
    margin: auto;
}
  