.btn {
  display: flex;
  text-decoration: none;
  color:  rgb(0,0,0) !important;
  background-color:rgba(199, 195, 195, 0.801) !important;
  font-size: 100%;
  justify-content: space-evenly;
  margin: 2% 0% 2% 0%;
  width: 100%;
  outline: none !important;
  border: none;
  border-radius: 15px;
  box-shadow: 10px 10px 7px #999 !important;
  padding: 0
}

.btn:hover {
  cursor: pointer;
  background-color: rgba(173, 173, 173, 0.801) !important;
}

.btn:active{
  box-shadow: 6px 6px 7px #999;
  transform: translateY(4px);
}

.dontShowOnMobile {
  display: flex;
  justify-content: center;
}

.table{
  font-size: 150%;
}

.custom-file-upload-rangliste {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: default;
  background: rgb(43, 59, 67);
}

@media (max-width: 768px) {
  .dontShowOnMobile {
    display: none;
  }
}